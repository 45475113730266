.Objective-container  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 40px 0;
}

.objective-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.objective-heading>h3{
    width: auto;
    position: relative;
    border-bottom: 2px solid rgb(114, 114, 114);
    text-align: left;
    padding: 5px;
  }
  .objective-heading>h3::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 5px;
    width: 50px;
    background-color: var(--main);
    transform: translate(-50%, 65%);
    border-radius: 30px;
  }

.objectives-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 50px;
    padding: 20px;
}

.objectives-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.objectives-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
}

.objectives-image>img {
    border: 1px solid rgb(223, 220, 220);
    width: 70%;
    aspect-ratio: 1;
    border-radius: 1000px;
    object-fit: fill;
}


/* Code For mobile View */

@media screen and (max-width:768px) {
    .Objective-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: rgb(255, 255, 255);
        padding: 40px 0;
    }
    
    
    .objectives-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px;
        padding: 20px;
    }
    
    .objectives-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    
    .objectives-image {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1;
    }
    
    .objectives-image>img {
        border: 1px solid rgb(223, 220, 220);
        width: 50%;
        aspect-ratio: 1;
        border-radius: 1000px;
        object-fit: fill;
    } 
    .objectives-box>h3{
        font-size: 13px;
    }
}