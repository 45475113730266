.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    height: 100vh;
}

.login-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.loginwrapperCon {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.loginwrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 1000px;
    height: 500px;
    /* background-color: red; */
    border-radius: 20px;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px 20px;
}

.loginform {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
}

.AMFLoginForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
}

.AMFLoginInputGroup {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 100%;
    gap: 10px;
}

.AMFLoginInputGroup>label {
    color: white;
    font-size: 16px;
}

.AMFLoginInputGroup>input {
    padding: 10px 15px;
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(10px);
    outline: none;
    border: none;
    border-radius: 4px;
    box-shadow: inset 1px 1px 4px rgba(2, 2, 2, 0.47);
    font-size: 16px;
    color: white;
}

.AMFLoginForm>.submitBTN {
    border: none;
    outline: none;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.823);
    padding: 8px 20px;
    font-size: 22px;
    cursor: pointer;
    border-radius: 6px;
    background-color: color-mix(in srgb, var(--main) 100%, transparent);
    color: black;
}

.AMFLoginForm>.submitBTN:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.2);
    transition: background-color 1s ease, color 1s ease;
}

.loginImg{
    padding: 40px;
}
.heading{
    color: white;
    text-align: center;
}

@media only screen and (max-width:680px) {
    .loginwrapper{

        grid-template-columns: 1fr;
    }
    .loginImg{
        display: none   ;
    }
    .loginwrapperCon{
        padding: 10px;
    }

     
}