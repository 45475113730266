.admin-membership-page {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
    width: 100%;
    height: auto;
    position: relative;
    padding: 10px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 10px;
    color: white;
}

.header p {
    font-size: 18px;
}

.member-data {
    width: 100%;
    overflow: hidden;
}

.table {
    width: 60%;
    background-color: rgba(5, 64, 64, 0.845);
    box-shadow: inset 1px 1px 50px rgba(2, 2, 2, 0.47);
    border-radius: 20px;
    margin-left: 15px;
    margin-right: 15px;
}


.Adminfooter {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 0 6%;
    margin-top: auto;
    background-color: var(--main-bg);
}

.footer p {
    font-size: 15px;
    color: white;
}

.footer>a {
    color: white;
    font-size: 15px;
    text-decoration: none;
}

.AdminMembershipTableImg {
    height: 70px;
    aspect-ratio: 1;
    overflow: hidden;
}

.AdminMembershipTableImg>img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.member-action-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.ViewMemberDetailsBtn {
    width: 20px;
    height: 20px;
    transition: all .3s ease;
}

.ViewMemberDetailsBtn:hover {
    transform: scale(1.2);
    color: orange;
}

.ViewMemberdeletBtn {
    width: 20px;
    height: 20px;
    transition: all .3s ease;
}

.ViewMemberdeletBtn:hover {
    transform: scale(1.2);
    color: rgb(178, 35, 35);
}

.ViewMemberEditBtn {
    width: 20px;
    height: 20px;
    transition: all .3s ease;
}

.ViewMemberEditBtn:hover {
    transform: scale(1.2);
    color: rgb(23, 158, 185);
}

.download-id-card {
    width: 20px;
    height: 20px;
    text-decoration: none;
    color: inherit;
}

.DownloadMemberIdCard {
    transition: all .3s ease;
}

.DownloadMemberIdCard:hover {
    transform: scale(1.2);
    color: rgb(0, 114, 17);
}

/* generate card popup  */
.generate-card-popup {
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}