.faq-container {
    width: 100%;
    height: auto;
    background-color: rgba(9, 8, 8, 0.918);
}

.faq-wrapper{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.faq-img img {
    margin-top: 10px;
    width: 100%;
    height: 300px;
    background-size: cover;
    border-bottom: 5px solid;
    box-shadow: 2px 5px 10px rgb(121, 89, 89);
}



.faq-box{
    width: 100%;
    height: auto;

}
.faq-heading{
color: #e3edf7;
padding: 10px;
}
.faq-heading p{
    margin-top: 10px;
}
.faq-heading h2:after {
    background-color: #c50000;
    content: '';
    display: block;
    position: absolute;
    /* right:300px;
    bottom: 0; */
    height: 5px;
    width: 100px;
    margin-bottom: 0.25em;
}


.accordion{
    margin: 60px auto;
    width: 80%;
}
.accordion li{
    list-style: none;
    width: 100%;
    margin: 10px;
    padding: 10px;
    border-radius: 8px;
    background:  #e3edf7;
    box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
                    -6px -6px -10px -1px rgba(255, 255, 255, 0.7);
}

.accordion li label{
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.accordion li label::before{
    content: "+";
    margin-right: 10px;
    font-size: 24px;
    font-weight: 600;
}

.faq-button{
    display: none;
}

.accordion .faq-containt{
    color: #555;
    padding: 0 10px;
    line-height: 26px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s, padding 0.5s;
}

.accordion .faq-button:checked + label +.faq-containt {
    max-height: 400px;
    padding:  10px 10px 20px;

}


.accordion .faq-button:checked + label::before{
    content: "-";
}


/* Code for mobile view */
@media screen and (max-width:768px) {
    .faq-container {
        width: 100%;
        height: auto;
        background-color: rgba(9, 8, 8, 0.918);
    }
    
    .faq-wrapper{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .faq-img img {
        margin-top: 10px;
        width: 100%;
        height: 150px;
        background-size: cover;
        border-bottom: 5px solid;
        box-shadow: 2px 5px 10px rgb(121, 89, 89);
    }
    
    
    
    .faq-box{
        width: 100%;
        height: auto;
    }
    .faq-heading{
    color: #e3edf7;
    padding: 10px;
    font-size: 14px;
    }
    .faq-heading p{
        margin-top: 10px;
        font-size: 13px;
    }
    .faq-heading h2:after {
        background-color: #c50000;
        content: '';
        display: block;
        position: absolute;
        /* right:300px;
        bottom: 0; */
        height: 5px;
        width: 100px;
        margin-bottom: 0.25em;
    }
    
    
    .accordion{
        width:auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .accordion li{
        list-style: none;
        width: 90%;
        margin: 10px;
        padding: 10px;
        border-radius: 8px;
        background:  #e3edf7;
        box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
                        -6px -6px -10px -1px rgba(255, 255, 255, 0.7);
    }
    
    .accordion li label{
        display: flex;
        align-items: center;
        padding: 10px;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
    }
    
    .accordion li label::before{
        content: "+";
        margin-right: 10px;
        font-size: 24px;
        font-weight: 600;
    }
    
    .faq-button{
        display: none;
    }
    
    .accordion .faq-containt{
        color: #555;
        padding: 0 10px;
        line-height: 26px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s, padding 0.5s;
        font-size: 14px;
    }
    
    .accordion .faq-button:checked + label +.faq-containt {
        max-height: 400px;
        padding:  10px 10px 20px;
    
    }
    
    
    .accordion .faq-button:checked + label::before{
        content: "-";
    }
    
}