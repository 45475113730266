.HomePageSlider {
    width: 100%;
    /* height: 500px; */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.HomePageSliderContainer {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HomeSliderImg {
    height: 500px;
    width: 100%;
    /* object-fit: cover; */
    object-fit: fill;
}

.splide__pagination {
    gap: 5px;
}
.splide__pagination__page {
    background-color: white !important;
    height: 12px !important;
    width: 12px !important;
}

.imgeslide{
    height: 300px;
    width: 500px;
    border-radius: 5px;
}


 /* For Mobile View */

 @media screen and (max-width:768px) {

    .HomePageSlider {
        width: 100%;
        /* height: 500px; */
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .HomePageSliderContainer {
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .HomeSliderImg {
        height: 500px;
        width: 100%;
        /* object-fit: cover; */
        object-fit: fill;
    }
    
    .splide__pagination {
        gap: 5px;
    }
    .splide__pagination__page {
        background-color: white !important;
        height: 12px !important;
        width: 12px !important;
    }

    .imgeslide{
        height: 180px;
        width: 200px;
        border-top: 5px;
    }
    
 }