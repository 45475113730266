.slideadd {
    padding-bottom:30px ;
    padding-top:30px ;
    width: 100%;
    height: auto;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.849);
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 200px;
    left: 400px;
    gap: 10px;
    border-radius: 5px;
    /* display: none; */
    /* box-shadow: 5px 5px 4px rgb(227, 227, 227); */
}
.slide-form{
    gap: 5px;
}


.AMFSliderInputGroup {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 80%;
    gap: 10px;
}

.AMFSliderInputGroup>label {
    color: rgb(99, 99, 99);
    font-size: 16px;
}

.AMFSliderInputGroup>input {
    padding: 10px 15px;
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(10px);
    outline: none;
    border: none;
    border-radius: 4px;
    box-shadow: inset 1px 1px 4px rgba(2, 2, 2, 0.47);
    font-size: 16px;
    color: rgb(93, 90, 90);
}
.AMFSliderInputGroup>textarea {
    padding: 10px 15px;
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(10px);
    outline: none;
    border: none;
    border-radius: 4px;
    box-shadow: inset 1px 1px 4px rgba(2, 2, 2, 0.47);
    font-size: 16px;
    color: rgb(93, 90, 90);
}
.slide-buttons{
    display: flex;
    flex-direction: row;
    align-content: center;
    width: 80%;
    gap: 5px;
}

