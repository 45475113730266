.founder-container{
  background-color:  #003d4d;
  display: grid;
  grid-template-columns:  1fr 400px;
  align-items: flex-start;
  padding: 20px 0;
}

.founder-subcontainer{
  display: flex;
  padding: 20px;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  position: relative;
}
.img-box{
  width: 300px;
  height: 350px;
  position: relative;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 16;
}
.img-box img {
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: contain;
  border-style: 2px 10px 80px red;
  background-color: white;
    z-index: 10;
}
.box1{
  content: '';
  width: 60px;
  height: 60px;
  background: linear-gradient(to right, #5205f0 ,white);
  position: absolute;
  left: -10px;
  top: -10px;
  z-index: 9;
}
.box2{
  content: '';
  width: 60px;
  height: 60px;
  background: linear-gradient(to right, white ,#5205f0);
  position: absolute;
  bottom: -10px;
  right: -10px;
  z-index: 9;
}
.founder-subcontainer2{
  color: rgb(255, 255, 255);
  /* padding: 50px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-left: 20px;
}

.Founder-heading{
  width: auto;
  position: relative;
  border-bottom: 2px solid rgb(114, 114, 114);
  text-align: left;
  padding: 5px;
}
.Founder-heading::after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 5px;
  width: 50px;
  background-color: var(--main);
  transform: translate(-50%, 65%);
  border-radius: 30px;
}
.founder-name{
  text-shadow: 3px 3px 6px rgba(154, 131, 131, 0.538);
}
.founder-subcontainer2 p{
  font-size: 1.2rem;
}



/* Mobile View code */
@media screen and (max-width:768px){

  .founder-container{
    background-color:  #003d4d;
    display: grid;
    grid-template-columns:  1fr ;
    align-items: flex-start;
    /* padding: 20px 0; */
  }

.founder-subcontainer2{
  color: rgb(255, 255, 255);
  /* padding: 50px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: start;
  margin-left: 20px;
}

.Founder-heading{
  width: auto;
  position: relative;
  border-bottom: 2px solid rgb(114, 114, 114);
  text-align: left;
  padding: 5px;
}
.Founder-heading::after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 5px;
  width: 50px;
  background-color: var(--main);
  transform: translate(-50%, 65%);
  border-radius: 30px;
}
.founder-name{
  text-shadow: 3px 3px 6px rgba(154, 131, 131, 0.538);
  font-size: 18px;
}

.founder-post{
  font-size: 15px;
}
.founder-subcontainer2 p{
  font-size: 13px;
}

.founder-subcontainer{
  display: flex;
  padding: 20px;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  position: relative;
}
.img-box{
  width: 180px;
  height: 220px;
  position: relative;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 16;
}
.img-box img {
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: contain;
  border-style: 2px 10px 80px red;
  background-color: white;
    z-index: 10;
}
.box1{
  content: '';
  width: 60px;
  height: 60px;
  background: linear-gradient(to right, #5205f0 ,white);
  position: absolute;
  left: -10px;
  top: -10px;
  z-index: 9;
}
.box2{
  content: '';
  width: 60px;
  height: 60px;
  background: linear-gradient(to right, white ,#5205f0);
  position: absolute;
  bottom: -10px;
  right: -10px;
  z-index: 9;
}




}


