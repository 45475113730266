.member-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    width: 100%;
    gap: 20px;
    padding: 20px;
    /* background-color: rgba(5, 64, 64, 0.845); */
    /* box-shadow: inset 1px 1px 50px rgba(2, 2, 2, 0.47); */
}

.membershipInput {
    padding: 10px 15px;
    backdrop-filter: blur(10px);
    outline: none;
    border: 1px solid var(--border);
    border-radius: 4px;
    font-size: 16px;
}

.membershipAreaLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 20px;
}

.membershipAreaLeft>form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 20px;
}

.MembershipStepFormContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    border: 1px solid var(--border);
    border-radius: 20px;
    overflow: hidden;
}

.progress {
    width: 100%;
    height: 10px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.actualProgress {
    transition: all .3s ease;
    background: linear-gradient(45deg, #8453ff, #e61134);
    /* background-color: red; */
    border-radius: 1000px;
}

.MembershipFormBox {
    padding: 10px 20px 20px 20px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.membershipStepForm {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.MembershipFormBox>.formHeader {
    text-align: center;
}

.membershipStepForm>.inputGroup {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.membershipStepForm>.inputGroup>label {
    font-size: 14px;
}
.membershipStepForm>.inputGroup>input {
    font-size: 14px;
}

.membershipStepForm>.formSubHeader {
    margin-bottom: 10px;
}

.gridBtnGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}


/* Code for Moible View */
@media screen and (max-width:768px) {
    .member-container {
        display: grid;
        grid-template-columns: 1fr ;
        width: 100%;
        gap: 20px;
        padding: 20px;
    }
    
    .membershipInput {
        padding: 10px 15px;
        backdrop-filter: blur(10px);
        outline: none;
        border: 1px solid var(--border);
        border-radius: 4px;
        font-size: 11px;
    }
    
    .membershipAreaLeft {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        padding: 20px;
    }
    
    .membershipAreaLeft>form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        padding: 20px;
    }
    
    .MembershipStepFormContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
        border: 1px solid var(--border);
        border-radius: 20px;
        overflow: hidden;
    }
    
    .progress {
        width: 100%;
        height: 10px;
        display: flex;
        flex-direction: row;
        overflow: hidden;
    }
    
    .actualProgress {
        transition: all .3s ease;
        background: linear-gradient(45deg, #8453ff, #e61134);
        /* background-color: red; */
        border-radius: 1000px;
    }
    
    .MembershipFormBox {
        padding: 10px 20px 20px 20px;
        gap: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    
    .membershipStepForm {
        width: 70%;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    
    .MembershipFormBox>.formHeader {
        text-align: center;
    }
    
    .membershipStepForm>.inputGroup {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    
    .membershipStepForm>.inputGroup>label {
        font-size: 14px;
    }
    .membershipStepForm>.inputGroup>input {
        font-size: 14px;
    }
    
    .membershipStepForm>.formSubHeader {
        margin-bottom: 10px;
    }
    
    .gridBtnGroup {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
}