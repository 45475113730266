.team-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}
.teamData{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 400px 1fr;
    background-color: rgba(149, 139, 139, 0.626);
    gap: 10px;
    padding: 20px;
}

.add-teamMember{
    background-color: rgb(247, 247, 247);
    width: 100%;
    height: auto;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
}



.input-Style {
    width: 250px;
    margin-left: 10px;
    padding: 10px 15px;
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(10px);
    outline: none;
    border: none;
    border-radius: 4px;
    box-shadow: inset 1px 1px 4px rgba(2, 2, 2, 0.47);
    font-size: 16px;
    color: white;
}