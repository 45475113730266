@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --main-bg: rgb(22, 46, 58);
  --main-darkbg: rgb(32, 50, 59);
  --main-lightbg: rgb(25, 81, 109);
  --main: rgb(21, 236, 125);
  --border: rgba(14, 51, 70, 0.453);
}

*, html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

body {
  overflow: hidden;
  overflow-y: auto;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}