/*  Dashbord header style */
.dashbord-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    max-height: 60px;
    background-color: var(--main-bg);
    position: relative;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    z-index: 122;
}

.brand{
    display: flex;
    justify-content:flex-start;
    align-items: center;
}
.dashbord-header  img{
    width: 40px;
    height: 40px;
    border-radius: 30px;
    border: solid white;
    margin-left: 30px;
}
.dashbord-header  h3{
    color: white;
  margin-left: 10px;
}