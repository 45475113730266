.intro-container{
    background-color: white;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr ;
    padding: 50px 200px 50px 200px;
}

.intro-wrapper{
 display: flex;
 flex-direction: column;
 justify-content: center;
 gap: 20px;
}
.block-box{
    padding: 20px;
    border-left: 5px solid;
    border-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.blockone{
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    font-style: italic;
}

.blocktwo{
    padding: 10px;
}

.blockthree{
    padding: 10px;
}

.blockfour{
    padding: 10px;
}



/* Code for mobile view */

@media screen and (max-width:768px) {
    .intro-container{
        background-color: white;
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 1fr;
        padding: 30px;
    }
    
    .intro-wrapper{
     display: flex;
     flex-direction: column;
     justify-content: center;
     gap: 20px;
    }
    .block-box{
        padding: 20px;
        border-left: 5px solid;
        border-color: red;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    
    .blockone{
        padding: 10px;
        font-size: 16px;
        font-weight: 500;
        font-style: italic;
    }
    
    .blocktwo{
        padding: 10px;
    }
    
    .blockthree{
        padding: 10px;
    }
    
    .blockfour{
        padding: 10px;
    }
}
