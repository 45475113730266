.admin-sidebar {
    height: calc(100vh - 60px);
    background-color: var(--main-bg);
}

.AdminSidebarBrand {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
}

.AdminSidebarBrand>h2 {
    color: white;
}

.AdminSidebarBrandImg {
    background-color: white;
    border-radius: 1000px;
    width: 100px;
    padding: 10px;
    aspect-ratio: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AdminSidebarBrandImg>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.admin-sidemenu {
    padding: 0 10px;
}

.admin-sidemenu-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.admin-sidemenu-links>li {
    width: 100%;
    transition: all .5s ease;
}

.admin-sidemenu-links>li>button,
.admin-sidemenu-links>li>a {
    transition: all .5s ease;
    border-radius: 5px;
    color: white;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    width: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}
.admin-sidemenu-links>li>button:hover,
.admin-sidemenu-links>li>a:hover {
    background-color: var(--main-lightbg);
}

.admin-sidemenu-links>li:has(> button:hover),
.admin-sidemenu-links>li:has(> a:hover) {
    padding-left: 15px;
}