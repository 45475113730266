.admin-slider-page {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
    width: 100%;
    height: auto;
    position: relative;
    padding: 10px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 10px;
    color: white;
}

.header p {
    font-size: 18px;
}

.member-data {
    width: 100%;
    overflow: hidden;
}

.table {
    width: 60%;
    background-color: rgba(5, 64, 64, 0.845);
    box-shadow: inset 1px 1px 50px rgba(2, 2, 2, 0.47);
    border-radius: 20px;
    margin-left: 15px;
    margin-right: 15px;
}


.Slider-nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
    padding-left: 50px;
    color: azure;
}

.Slider-nav div {
    display: flex;
    cursor: pointer;
    gap: 10px;
    font-size: 20px;
}

.slideadd {
    width: 500px;
    height: 500px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.849);
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 100px;
    left: 400px;
    gap: 10px;
    border-radius: 5px;
    /* display: none; */
    /* box-shadow: 5px 5px 4px rgb(227, 227, 227); */
}
.slide-form{
    gap: 5px;
}

.Slide-delete{
        background-color: #1ed1dad2;
        padding: 15px 30px;
        border: none;
        color: white;
        border-radius: 30px;
        font-weight: bold;
        transition: 1.3s ;
        border-style: solid;
        border-color: white;
}
.Slide-delete:hover{
    background-color: rgb(255, 0, 0);
    padding: 15px 30px;
    border: none;
    color: #ffffff;
    border-radius: 30px;
    font-weight: bold;
    transition: 1.3s;
    border-color: #ffffff;
    border-style: solid;
    border-color: #ffffffd2;
}

.AMFSliderInputGroup {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 80%;
    gap: 10px;
}

.AMFSliderInputGroup>label {
    color: rgb(99, 99, 99);
    font-size: 16px;
}

.AMFSliderInputGroup>input {
    padding: 10px 15px;
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(10px);
    outline: none;
    border: none;
    border-radius: 4px;
    box-shadow: inset 1px 1px 4px rgba(2, 2, 2, 0.47);
    font-size: 16px;
    color: rgb(93, 90, 90);
}

.slide-buttons{
    display: flex;
    flex-direction: row;
    align-content: center;
    width: 80%;
    gap: 5px;
}

/* .AdminMembershipTable {
    width: 1000px;
} */