.admin-dashboard-page {
  display: grid;
  gap: 5px;
  width: 100%;
  padding: 20px;
}

.dashboardRow1 {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
}

.dashboardCounters {
  display: grid;
  grid-template-columns: minmax(0, 60px) minmax(0, 1fr);
  align-items: center;
  width: 100%;
  height: 150px;
  border-radius: 15px;
  gap: 15px;
  font-family: var(--poppins);
  padding: 0 20px;
}

.dashboardCounters.c1 {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.dashboardCounters.c2 {
  background: linear-gradient(45deg, #ffb64d, #ffcb80);
}

.dashboardCounters.c3 {
  background: linear-gradient(45deg, #4099ff, #73b4ff);
}

.dashboardCounters.c4 {
  background: linear-gradient(45deg, #ff5370, #ff869a);
}
.counterIcon {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 545px;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.counterIcon>svg {
  color: #fff;
  font-size: 25px;
}
.dashCounterData {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.dashCounterData .counterCount {
  font-size: 16px;
  color: #fff;
}

.dashCounterData .counterTitle {
  font-size: 17px;
  color: #fff;
}

.dashCounterData .counterTitle.noWrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.counterProgress {
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.2);
}

.counterProgress>p {
  transition: all .5s ease;
  background-color: #fff;
  height: 2px;
} 