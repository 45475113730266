.humanrights-container {
    width: 100%;
    height: auto;
    background-color: rgba(9, 8, 8, 0.918);
}

.humanrights-img img {
    margin-top: 10px;
    width: 100%;
    height: 300px;
    background-size: cover;
    border-bottom: 5px solid;
    box-shadow: 2px 5px 10px rgb(121, 89, 89);
}

.humanrights-infobox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 20px;
    right: 20px;
}

.humanrights-info {
    background-color: rgba(255, 255, 255, 0.937);
    width: 95%;
    height: auto;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 10px;
    border-radius: 20px 5px;
}

.humanrights-heading {
 
    font-size: 18px;
    padding-bottom: 13px;
}



.humanrights-heading h3:after {
    background-color: #c50000;
    content: '';
    display: block;
    position: absolute;
    /* right:300px;
    bottom: 0; */
    height: 3px;
    width: 75px;
    margin-bottom: 0.25em;
}

.humanrights-info a {
    text-decoration: none;
    margin-top: 5px;
}




/* code for mobile view */
@media screen and (max-width: 768px) {
    .humanrights-container {
        width: 100%;
        height: auto;
        background-color: rgba(9, 8, 8, 0.918);
    }
    
    .humanrights-img img {
        margin-top: 10px;
        width: 100%;
        height: 150px;
        background-size: cover;
        border-bottom: 5px solid;
        box-shadow: 2px 5px 10px rgb(121, 89, 89);
    }
    
    .humanrights-infobox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 20px;
        right: 20px;
    }
    
    .humanrights-info {
        background-color: rgba(255, 255, 255, 0.937);
        width: 95%;
        height: auto;
        padding: 20px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 10px;
        border-radius: 20px 5px;
    }
    
    .humanrights-heading {
     
        font-size: 15px;
        padding-bottom: 13px;
    }
    
    
    
    .humanrights-heading h3:after {
        background-color: #c50000;
        content: '';
        display: block;
        position: absolute;
        /* right:300px;
        bottom: 0; */
        height: 3px;
        width: 75px;
        margin-bottom: 0.25em;
    }
    
    .humanrights-info a {
        text-decoration: none;
        margin-top: 5px;
    }

    .humanrights-heading>p{
        font-size: 10px;
    }
}    