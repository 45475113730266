.admin-container{
    width: 100%;
    height: 100vh;
}

.Admin-Wrapper{
    display: grid;
    grid-template-columns: 250px 1fr;
    width: 100%;
    height: calc(100vh - 60px);
    max-height:  calc(100vh - 60px);
    overflow: hidden;
}



.AdminContentArea {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--main-darkbg);
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
}