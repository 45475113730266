.LetestActivity-comtainer{
    background-color: rgb(2, 43, 53);
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap :10px;
}

.LetestActivity-Wrapper{
    display: grid;
    grid-template-columns: 20% 1fr 20%;
    padding : 20px 20px 20px 20px;
    height: auto;
    width: 100%;
}
.LetestActivity-block1{
    background-color: aqua;
}

.LetestActivity-block2{
display: flex;
flex-direction: column;
align-items:center;
}
.LetestActivity-block3{
    background-color: aqua;
}

.LetestActivity-block2-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Quick-buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 100%;
}
.Quick-buttons>img{
    width: 80px;
    height: 80px;
   cursor: pointer;
}
.LetestActivity-mainEvent{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
     width: 100%;
    height: 500px;
    margin-top: 20px;
}

.demoimg{
width: auto ;
height: 500px;}

.LetestActivity-Heading{
    display: flex;
    margin-top: 20px;
    align-content: center;
    justify-content: center;
    padding-top: 20px;
    font-weight: 500;
    font-size: 20px;
    color: rgb(255, 255, 255);
    position: relative;
    border-bottom: 2px solid rgb(114, 114, 114);
}
.LetestActivity-Heading.black {
    color: black;
}
.LetestActivity-Heading.m0p0 {
    margin: 0;
    padding: 0;
}

.LetestActivity-Heading::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 5px;
    width: 50px;
    background-color: var(--main);
    transform: translate(-50%, 65%);
    border-radius: 30px;
  }

@media screen and (max-width : 768px) {
    .LetestActivity-comtainer{
        background-color: rgb(2, 43, 53);
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap :10px;
    }

    .LetestActivity-Wrapper{
        display: grid;
        grid-template-columns: 1fr;
        padding : 20px 20px 20px 20px;
    }
    
}