.HomePageGallery {
    padding: 40px 20px;
    background-color: rgb(227, 227, 227);
    
}
.HomePageGallery > h2 {
    text-align: center;
    margin-bottom: 10px;
}

.HomePageGalleryContainer {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

.HomePageGalleryItem {
    width: 100%;
    aspect-ratio: 1.5;
    overflow: hidden;
}
.HomePageGalleryItem > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}


/* Code For Mobile View */
@media screen and (max-width:768px) {
    
    .HomePageGallery {
        padding: 40px 20px;
        background-color: rgb(227, 227, 227);
        
    }
    .HomePageGallery > h2 {
        text-align: center;
        margin-bottom: 10px;
    }
    
    .HomePageGalleryContainer {
        display: grid;
        gap: 20px;
        grid-template-columns:1fr 1fr;
    }
    
    .HomePageGalleryItem {
        width: 100%;
        aspect-ratio: 1.5;
        overflow: hidden;
    }
    .HomePageGalleryItem > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}