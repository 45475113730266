.info-container{
    width: 100%;
    height: auto;
    background: linear-gradient(90deg, rgba(22,12,199,1) 0%, rgba(238,0,0,1) 100%, rgba(0,212,255,1) 100%);

display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content:center ;
    gap: 15px;
}
.infobox{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    color: rgb(255, 255, 255);
}


@media screen and (max-width:768px){

    .info-container{
        width: 100%;
        height: auto;
        background: linear-gradient(90deg, rgba(22,12,199,1) 0%, rgba(238,0,0,1) 100%, rgba(0,212,255,1) 100%);
    
    display: grid;
        grid-template-columns:1fr;
        align-items: center;
        justify-content:center ;
        gap: 15px;
    }
    .infobox{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
        color: rgb(255, 255, 255);
    }

    .infobox>p{
      font-size: 13px;
    }

}