.ManagementTeam-container{
    background-color: rgb(2, 43, 53);
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap :10px;
    padding-bottom: 30px;
}
.ManagementTeam-hedding{
    margin-top: 20px;
    align-content: center;
    padding-top: 20px;
    font-weight: 500;
    font-size: 20px;
    color: white;
    position: relative;
    border-bottom: 2px solid rgb(114, 114, 114);
}
.ManagementTeam-hedding.black {
    color: black;
}
.ManagementTeam-hedding.m0p0 {
    margin: 0;
    padding: 0;
}

.ManagementTeam-hedding::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 5px;
    width: 50px;
    background-color: var(--main);
    transform: translate(-50%, 65%);
    border-radius: 30px;
  }

  .home-container{
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}
.profile-card{
    position: relative;
    width: 220px;
    height: 220px;
    background-color: #fff;
    padding: 30px;
    border-radius: 50%;
    box-shadow: -5px 8px 45px rgba(51, 51, 51, 0.126);
    transition: all .4s;
    margin: 0 20px;
}
.profile-card:hover{
    border-radius: 10px;
    height: 260px;
}
.profile-card .img{
    position: relative;
    width: 100%;
    height: 100%;
}
.profile-card:hover img{
    border-radius: 10px;
    transform: translateY(-70px);
}
.img img{
    object-fit: fill;
    width: 100%;
    border-radius: 50%;
    transition: all .4s;
    z-index: 99;
}
.caption{
    text-align: center;
    transform: translateY(-90px);
    opacity: 0;
    pointer-events: none;
    transition: all .5s;
}
.profile-card:hover .caption{
    opacity: 1;
    pointer-events: all;
}
.caption h3{
    font-size: 15px;
    color: #0c52a1;
    font-weight: 500;
}
.caption p{
    font-size: 12px;
    font-weight: 600;
    margin: 2px 0 12px 0;
   
}
.caption .social-links i{
    font-size: 21px;
    margin: 0 3px;
    cursor: pointer;
    color: #333;
    transition: all .4s;
}
.caption .social-links i:hover{
    color: #0c52a1;
}




/* Code for mobile View */

@media screen and (max-width:768px) {
    
    .ManagementTeam-container{
        background-color: rgb(2, 43, 53);
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap :10px;
        padding-bottom: 30px;
    }
    .ManagementTeam-hedding{
        margin-top: 20px;
        align-content: center;
        padding-top: 20px;
        font-weight: 500;
        font-size: 20px;
        color: white;
        position: relative;
        border-bottom: 2px solid rgb(114, 114, 114);
    }
    .ManagementTeam-hedding.black {
        color: black;
    }
    .ManagementTeam-hedding.m0p0 {
        margin: 0;
        padding: 0;
    }
    
    .ManagementTeam-hedding::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        height: 5px;
        width: 50px;
        background-color: var(--main);
        transform: translate(-50%, 65%);
        border-radius: 30px;
      }
  
    
      .home-container{
        padding: 50px 0 30px 0;
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr ;
        align-items: center;
        gap: 20px;
    }
    .profile-card{
        position: relative;
        width: 150px;
        height: 150px;
        background-color: #fff;
        padding: 30px;
        border-radius: 50%;
        box-shadow: -5px 8px 45px rgba(51, 51, 51, 0.126);
        transition: all .4s;
        margin: 0 20px;
    }
    .profile-card:hover{
        border-radius: 10px;
        height: 180px;
        }
    .profile-card .img{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .profile-card:hover img{
        border-radius: 10px;
        transform: translateY(-70px);
    }
    .img img{
        object-fit: fill;
        width: 100%;
        border-radius: 50%;
        transition: all .4s;
        z-index: 99;
    }
    .caption{
        padding-top: 30px;
        text-align: center;
        transform: translateY(-90px);
        opacity: 0;
        pointer-events: none;
        transition: all .5s;
    }
    .profile-card:hover .caption{
        opacity: 1;
        pointer-events: all;
    }
    .caption h3{
        font-size: 12px;
        color: #0c52a1;
        font-weight: 500;
    }
    .caption p{
        font-size: 12px;
        font-weight: 600;
        margin: 2px 0 12px 0;
       
    }
    .caption .social-links i{
        font-size: 15px;
        margin: 0 3px;
        cursor: pointer;
        color: #333;
        transition: all .4s;
        display: none;
    }
    .caption .social-links i:hover{
        color: #0c52a1;
    }

}