.ourRights-contaner{
    display: grid;
    grid-template-columns: 1fr;
    padding: 10px 10px 10px 10px;
}
.ourrights-box{
    align-items: center;
    border-width: 4px;
    border-style: solid;
    padding: 10px;
    border-image: linear-gradient(to right, darkblue, darkorchid) 1;
}
.ourrights-box>h3{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
}
.ourrights-wrapper{
    width: auto;
    height: auto;
    background-color: white;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}
.ourRight-card{
    background-color: rgba(0, 183, 255, 0.912);
    padding: 10px 0 10px 0 ;
    color: white;
}
.ourRight-card>ul{
    padding: 10px 10px 10px 40px;
}
.ourRight-card>h5{
    text-align: center;
    padding: 10px 0 10px 0 ;
}


@media screen and (max-width:768px){
    .ourRights-contaner{
        display: grid;
        width: 100%;
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;
    }
    .ourrights-box{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        border-width: 4px;
        border-style: solid;
        border-image: linear-gradient(to right, darkblue, darkorchid) 1;
    }
    .ourrights-box>h3{
        text-align: center;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .ourrights-wrapper{
        width: auto;
        height: auto;
        background-color: white;
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr;
    }
    .ourRight-card{
        background-color: rgba(0, 183, 255, 0.912);
        padding: 10px 0 10px 0 ;
        color: white;
    }
    .ourRight-card>ul{
        padding: 10px 10px 10px 40px;
    }
    .ourRight-card>h5{
        text-align: center;
        padding: 10px 0 10px 0 ;
    }
}