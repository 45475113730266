.popup-container-backdrop {
    position: fixed;
    top: 0;
    right: -500px;
    width: 500px;
    height: 100vh;
    background-color: var(--main-bg);
    border-radius: 10px 0 0 10px;
    z-index: 545;
    transition: all .5s ease;
    box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    color: white;
}

.descLine>b {
    color: rgba(255, 255, 255, 0.411);
    font-weight: 400;
    font-size: 12px;
}

.popup-container-backdrop.open {
    right: 0;
}

.popup-container {
    padding: 10px;
}

.adminCard.member-left {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}

.descLine {
    display: flex;
    flex-direction: column;
}
.descLine.flexRow {
    flex-direction: row;
    gap: 5px;
}

.memberPopupBtns {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
}