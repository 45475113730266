.mapPage {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    font-family: var(--ff);
    padding: 40px 0;
}
.mapPage > h2 {
    text-align: center;
    font-size: 28px;
}

.MapInnerContainer {
    max-width: 1280px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    width: 100%;
}


.MapDetailsArea {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.MapDetailItem {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 10px;
}
.MapDetailItemIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexCol{
    display: flex;
    flex-direction: column;
} 
.gapSM {
    gap: 5px;
} 

.ContactFormWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.ContactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 600px;
    box-shadow: 4px 6px 20px rgba(0, 0, 0, 0.2);
    padding: 20px 40px;
    border-radius: 10px;
}

.CInputGroup {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}


.CInputGroup > label {
    font-size: 14px;
}
.CInputGroup > input {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    outline: none;
    border: 1px solid #333;
    border-radius: 4px;
}

.ContactSubmitBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ContactSubmitBtn > button {
    width: 200px;
    padding: 13px 10px;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    border: 1.5px solid #666;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    margin-top: 10px;
    font-weight: 600;
    transition: all .2s ease-in-out;
    /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3); */
}
.ContactSubmitBtn > button:hover {
    background-color: orange;
    color: white;
}

@media  screen and (max-width: 768px) {
    .MapInnerContainer {
        grid-template-columns: 1fr;
    }

    .MapArea {
        margin: 20px  30px 20px 30px;
        overflow: hidden;
        border: 3px solid black;
        border-top-right-radius:  20px;
        border-bottom-left-radius:  20px; 
    }
    .MapArea > iframe {
        width: 100%;
    }


    
.MapDetailsArea {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.MapDetailItem {
    display: grid;
    grid-template-columns:  1fr;
    gap: 10px;
    padding: 20px;
}
.MapDetailItemIcon {
    display: flex;
    justify-content: start;
    align-items: center;
}

.flexCol{
    display: flex;
    flex-direction: column;
} 
.gapSM {
    gap: 5px;
} 

.ContactFormWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
}