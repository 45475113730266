header{
    position: relative;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;    
    background-color: var(--main-bg);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 654;
}

.nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 1099;
    height: 100%;
    /* background-color: var(--main-bg); */
    /* background-color: blue;
     */
     background: rgb(22,12,199);
background: linear-gradient(90deg, #160cc7 0%, rgba(238,0,0,1) 100%, rgba(0,212,255,1) 100%);
}
.nav-brand{
    display: flex;
    padding-left: 20px;
    align-items: center;
}
.brand-logo{
    width: 40px;
    height: 40px;
    border-style:solid;
    border-color: white;
    border-radius: 100%;
}
.brand-name{
    padding-left: 20px;
    color: white;
    font-weight: bold;
    text-decoration:  none;
}
.nav-links li{
    display: inline;
}
.nav-links li a{
    gap: 1rem;
    position: relative;
    margin-left: 20px; 
    text-decoration: none;
    color: #cdc8c8;
    letter-spacing: 0.5px;
    transition: 0.2s;
    list-style: none;
}
.nav-links li a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0%;
    height: 2px;
    background-color: yellowgreen;
    transition: 0.5s linear;
}

.nav-links li a:hover {
    color: #fff;
}
.nav-links li a:hover:after {
    width: 100%;
}

.btn-member{
    background-color: #1ed1dad2;
    padding: 15px 30px;
    margin-right: 80px;
    border: none;
    color: white;
    border-radius: 30px;
    font-weight: bold;
    transition: 1.3s ;
    border-style: solid;
    border-color: white;
}
.btn-member:hover{
    background-color: white;
    padding: 15px 30px;
    margin-right: 80px;
    border: none;
    color: #ea1538;
    border-radius: 30px;
    font-weight: bold;
    transition: 1.3s;
    border-color: #ea1538;
    border-style: solid;
    border-color: #1ed1dad2;
 }

 .toggle-button{
    color: white;
    font-size: 2.5rem;
    padding-right:7px;
    cursor: pointer;
    display: none;
 }

 .nav-links.mobile {
    display: none;
    position: absolute;
    top: calc(-100% - 160px);
    right: 0;
    width: 100vw;
    background-color: var(--main-bg); 
    flex-direction: column;
    padding: 20px 10px;
    gap: 10px;
    transition: top 0.8s ease;
    z-index: 100;
}



.nav-links.mobile.active {
    top: 80px;
}

/* .nav-links.mobile li {
    
} */

 @media screen and (max-width:768px) {
        header{
            width: 100%;
        }
        
        .toggle-button{
            display: block;
        }
        
        .nav-links.mobile { 
            display: flex;
         }

         .btn-member,
        .nav-links.desktop{ 
            display: none;
        }
        
      
 }