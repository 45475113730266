.banner-Container {
    display: grid;
    width: 100%;
    height: 80vh;
    grid-template-columns: 35% 1fr;
    color: white;
    background: linear-gradient(90deg, rgba(22,12,199,1) 0%, rgba(238,0,0,1) 100%, rgba(0,212,255,1) 100%);
}

.banner-hedding-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.banner-heading {
    font-size: 40px;
    padding: 0 20px 5px 50px;
}

.banner-subinfo {
    font-size: 10px;
    padding: 0 20px 5px 50px;
}

.banner-subheading {
    font-size: 20px;
    padding: 0 20px 5px 50px;
}

.banner-btn {
    margin-top: 50px;
}

.banner-slides {
    min-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-joinus {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}


 /* For Mobile View */
@media screen and (max-width:768px) {
    .banner-Container{
       
            display: grid;
            grid-template-columns:  1fr ;
            align-items: flex-start;
            justify-content: revers;
            padding: 20px 0;
            color: white;
            background: linear-gradient(90deg, rgba(22,12,199,1) 0%, rgba(238,0,0,1) 100%, rgba(0,212,255,1) 100%);
    }

    .banner-heading {
        font-size: 15px;
        padding: 0 10px 3px 30px;
    }

    
    .banner-subheading {
        font-size: 10px;
        padding: 0 20px 5px 30px;
    }
    .banner-subinfo {
        font-size: 12px;
        padding: 0 10px 3px 30px;
    }
    
    .banner-btn {
        margin-top: 50px;
        margin-left: 20px;
        padding: 6px 10px;
        font-size: 14px;
    }
  }