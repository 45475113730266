.Footer {
    display: flex;
    flex-direction: column;
    background-color: var(--main-bg);
    margin-top: auto;
    color: white;
    align-items: center;
}

.FooterGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
}

.footerItem {
    padding: 20px;
}

.FooterBrandInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.FooterBrandInfo>img {

    width: 100px;
    height: 100px;
}

.FooterLinks>h3 {
    margin-bottom: 10px;
}

.footer-link-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    list-style: none;
}

.footer-link-list>li>a {
    color: white;
    text-decoration: none;
}

.FooterJoinAndDonation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.bottomFooter {
    width: 100%;
    height: 50px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 0 6%;
}

.bottomFooter>p>a {
    color: white;
    text-decoration: none;
}

.topFooter,
.mainFooter {
    max-width: 90%;
}

/* TopFooterItem  */
.topFooter {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 20px 0;
    width: 100%;
}

.TopFooterItem {
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    gap: 10px;
    /* justify-self: center; */
}

.TopFooterItem>svg {
    color: var(--main);
}

.TopFooterItem>div {
    display: flex;
    flex-direction: column;
    /* gap: 2px; */
}

.FooterFixedBtn {
    width: 200px;
}

.FooterFixedBtn.btn1 {
    margin-top: 10px;
}

.FooterSocialMediaLinks>h3 {
    margin-bottom: 10px;
}

.FooterSocialMediaLinksItems {
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 10px;
}

.FooterSocialMediaLinksItem {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 1000px;
    background-color: var(--main-lightbg);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .6s ease;
}

.FooterSocialMediaLinksItem>svg {
    transition: all .6s ease;
}

.FooterSocialMediaLinksItem:hover {
    background-color: white;
}

.FooterSocialMediaLinksItem:hover>svg {
    color: var(--main-lightbg);
}


.PaymentButton.PaymentButton {
    margin-top: 50px;
    -webkit-text-size-adjust: 100%;
    line-height: 1.7;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    transition: color .3s linear, background .3s linear;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    min-width: 160px;
    height: 50px;
    padding: 0;
    border-radius: 3px;
    text-align: center;
    font-style: italic;
    font-family: Muli, helvetica, sans-serif;
    overflow: hidden;
    border: 1px solid transparent;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    color: #fff;
    background: rgb(7, 25, 249);
}

.PaymentButton.PaymentButton svg {

    -webkit-text-size-adjust: 100%;
    line-height: 1.7;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    font-style: italic;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    color: #fff;
    width: 18;
    height: 20;
    fill: none;
    font-family: 'Open Sans', sans-serif;
    overflow: hidden;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    margin: 9px 11px;
}

.PaymentButton.PaymentButton div {

    -webkit-text-size-adjust: 100%;
    line-height: 1.7;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    font-style: italic;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    padding: 4px 28px 4px 44px;
    margin: 1px 0;
}

.PaymentButton-securedBy.svelte-ekc7fv.svelte-ekc7fv {

    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    font-style: italic;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    font-size: 8px;
    line-height: 10px;
    text-transform: initial;
    margin-top: 0;
    opacity: .6;
}

.PaymentButton-text.svelte-ekc7fv.svelte-ekc7fv {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    font-style: italic;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    display: block;
    min-height: 18px;
    line-height: 18px;
    font-size: 14px;
    font-weight: 800;
    opacity: 1;
    text-transform: initial;
}


@media  screen and (max-width: 768px) {
    .topFooter {
        grid-template-columns: 1fr;
        gap: 20px;
        font-size: 15px;
    }

    .FooterGrid {
        row-gap: 40px;
        padding: 20px 0;
        grid-template-columns: 1fr;
    }

    .footerItem {
        padding: 0;
    }

    .bottomFooter {
        height: 70px;
    }
    .bottomFooter>p>a {
       cursor: pointer;
       text-decoration: none;
       color: #fff;
    }

}